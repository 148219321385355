// assets/js/app.js
/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../sass/app.sass';
import $ from 'jquery';
global.$ = global.jQuery = $;
require('bootstrap');
import 'slick-carousel';
import 'select2';

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
// import $ from 'jquery';



jQuery(function($) {

    $(document).on( 'input', 'input[type=number][max]:not([max=""])', function(){
        var $this = $(this);
        var maxlength = $this.attr('max').length;
        var value = $this.val();
        if (value && value.length >= maxlength) {
            $this.val(value.substr(0, maxlength));
        }
    });

    /**
     * Smooth scrolling to page anchor on click
     **/
    $("a[href*='#']:not([href='#']):not(.marquageDropdown):not([data-toggle='collapse'])").on('click', function(e) {
        if (
            location.hostname == this.hostname
            && this.pathname.replace(/^\//,"") == location.pathname.replace(/^\//,"")
            && !( $(this).attr('data-toggle') == 'tab' )
        ) {
            e.preventDefault();
            var anchor = $(this.hash);
            anchor = anchor.length ? anchor : $("[name=" + this.hash.slice(1) +"]");
            if ( anchor.length ) {
                $("html, body").animate( { scrollTop: anchor.offset().top - 100 }, 600);
            }
        }
    });

    $('select').select2();

    function toggleDropdown (e) {
        const _d = $(e.target).closest('.dropdown'),
        _m = $('.dropdown-menu', _d),
        _n = $(e.target).closest('.navbar-light');
        setTimeout(function(){
            const shouldOpen = e.type !== 'click' && _d.is(':hover');
            //console.log(shouldOpen);
            _m.toggleClass('show', shouldOpen);
            _d.toggleClass('show', shouldOpen);
            //console.log($('.dropdown-menu.show').length);

            if($('.dropdown-menu.show').length>0) {
                $('.navbar-light').addClass('show');
            }else{
                $('.navbar-light').removeClass('show');
            }

            //_n.toggleClass('show', shouldOpen);
            $('[data-toggle="dropdown"]', _d).attr('aria-expanded', shouldOpen);
            //console.log($('[data-toggle="dropdown"]', _d).attr('aria-expanded'));
        }, e.type === 'mouseleave' ? 0 : 0);
    }

    if ($(".secondary-navbar .dropdown-menu li.active").length){
        $(".secondary-navbar .dropdown-menu li.active").parents("li.dropdown").addClass("active");
    }
    
    //var burgerBtn = document.getElementById('burgerBtn');
    var burgerBtn = document.getElementById('burgerBtn');
    var mobile = document.getElementById('body');

    burgerBtn.addEventListener('click', function() {
        $('body').toggleClass('mobilemenu-open');
        $('.menu-toggle').toggleClass('active');
    }, false);


    $('body')
      .on('mouseenter mouseleave','.dropdown',toggleDropdown);

    $('[data-toggle="popover"]').popover({
        trigger: 'focus'
    });

    $('[data-toggle="tooltip"]').tooltip();

    $(".slick-slider-v1").not('.slick-initialized').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 800,
        dots: true,
        arrows: false,
        rows: 0
    });


    $('.slickproductslider').not('.slick-initialized').slick({
        infinite: false,
        arrows: true,
        dots: false,
        rows: 0,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false
                }
            }
        ]
    });

    $('#goto_searchbox, .goto_searchbox, #product-search-form .close, #product-search-form .logo img').on('click', function(e){
        e.preventDefault();
        $('#product-search-form').toggleClass('show');

        if( $('#product-search-form').hasClass('show') ) {
            $('#product-search-form input.cyb_search').focus();
        }
    });

    $('#fos_login_form').ajaxForm({success: loginResponse});

    function loginResponse(data, textStatus, jqXHR) {
        // If Json response with data.success == true : correct login then reloading or redirection
        if(typeof data.success != undefined && data.success == true) {
            if(typeof data.redirect != undefined) {
                if(data.redirect == "reload")
                    location.reload();
                else
                    window.location = data.redirect;
            }
            else
                location.reload();
        }
        // else, login error, display form + error
        else {
            window.location = '/login';
//            $('#ModalLogin .modal-content').html(data);
//            $('#ModalLogin #fos_login_form').ajaxForm({success: loginResponse});
        }
    };
});






